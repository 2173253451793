import { Injectable } from '@angular/core';
import { Toast } from 'primeng/toast';
import { Subject } from 'rxjs';
import { ToastActionEvent } from '../models';

@Injectable({
  providedIn: 'root',
})
export class AmmToastService {
  public onToastClose: Subject<any> = new Subject<AnalyserNode>();
  public onToastActionClicked: Subject<ToastActionEvent> =
    new Subject<ToastActionEvent>();
  public toastComponent: Toast | undefined = undefined;
}
